import store from "@/store";
import { updateCheckListFromFeatures } from "./onboarding/adminOnboarding.js";
import { find, camelCase } from "lodash";
import {
  setFeatureIntroItemComplete,
  getActiveFeatureIntroductions
} from "@/customApi";

export default {
  toggleLegacyFeature(key, enable) {
    var legacyEnabled = this.legacyFeatureEnabled(key);
    if (enable && !legacyEnabled) {
      localStorage.setItem(key, "true");
    } else if (!enable && legacyEnabled) {
      localStorage.removeItem(key);
    }
  },
  legacyFeatureEnabled(key) {
    return !!localStorage.getItem(key);
  },
  showFeature(key) {
    let featureIntros = store.getters.featureIntros;
    let onboardingShown = store.getters.showOnboardingChecklist;
    if (onboardingShown) return false;

    let uiHighlightIntro = find(
      featureIntros,
      intro => intro.series === "NewUIHighlight"
    );
    let selfTitleIntro = find(featureIntros, intro => intro.series === key);
    let intro = selfTitleIntro ?? uiHighlightIntro;
    if (!intro) return false;

    let item = intro.items[camelCase(key)];

    if (!item?.id || item.completed) {
      return false;
    }

    return true;
  },
  async markFeatureSeen(key, force) {
    let featureIntros = store.getters.featureIntros;

    let uiHighlightIntro = find(
      featureIntros,
      intro => intro.series === "NewUIHighlight"
    );
    let selfTitleIntro = find(featureIntros, intro => intro.series === key);
    let intro = selfTitleIntro ?? uiHighlightIntro;
    if (!intro?.id) return;

    let item = intro.items[camelCase(key)];

    if (!item?.id) return;

    let updated = await setFeatureIntroItemComplete(intro.id, item.id);
    if (force) {
      store.commit("setFeatureIntros", updated);
    }
  },
  async getActiveFeatureIntros() {
    var featureIntros = null;
    try {
      featureIntros = await getActiveFeatureIntroductions();
      await updateCheckListFromFeatures(featureIntros);
    } catch (error) {
      // suppress, already in Sentry
    } finally {
      store.commit("setFeatureIntros", featureIntros);
    }
  }
};
