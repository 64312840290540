<template>
  <div>
    <pain-person
      class="py-8 mx-auto"
      ref="painPerson"
      v-model="value"
      :linkedToQuestions="true"
      assessmentType="deskAssessment"
      :showWhyDoWeAskThisButton="false"
    />
    <pain-question-panels
      :questions="questions"
      class="pb-8"
      ref="painAreaPanels"
      :results="fullResults"
      @removePainArea="$refs.painPerson.confirmRemovePainArea($event)"
      :assessmentType="assessmentType"
    />
  </div>
</template>

<script>
import PainQuestionPanels from "@/components/deskassessment/PainQuestionPanels.vue";
import PainPerson from "@/components/painareas/PainPerson.vue";

export default {
  name: "PainForm",
  components: { PainPerson, PainQuestionPanels },
  props: {
    questions: Object,
    value: Object,
    fullResults: Object,
    assessmentType: {
      type: String,
      required: true
    }
  }
};
</script>
