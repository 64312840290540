<template>
  <div>
    <h2>{{ welcomeMessage }}</h2>
    <p>{{ $t("msTeams.dashboard.description") }}</p>
    <button
      v-if="signedIn"
      class="btn btn-primary"
      @click="openSendAssessmentsDialog"
    >
      {{ $t("msTeams.dashboard.sendAssessmentsButton") }}
    </button>
    <button v-else class="btn btn-primary" @click="openAuthDialog">
      {{ $t("msTeams.dashboard.authButton") }}
    </button>
  </div>
</template>

<script>
import * as microsoftTeams from "@microsoft/teams-js";
import {
  sendAssessments,
  linkMsTeamsUser
} from "@/components/ms-teams/ms-teams-api";
import { Auth } from "aws-amplify";
import { mapGetters, mapMutations } from "vuex";
import { userTeamMemberInformation } from "@/customApi";

export default {
  name: "MsTeamsDashboardPage",
  data() {
    return {
      authToken: "",
      appId: "",
      userName: "",
      userId: "",
      tenantId: ""
    };
  },
  computed: {
    ...mapGetters(["signedIn"]),
    welcomeMessage() {
      return `${this.$t("msTeams.dashboard.welcomeMessage")} ${this.userName}`;
    }
  },
  async mounted() {
    try {
      await this.initializeTeams();
      await this.loadTeamsContext();
      await this.handleMsTeamsAuth();
    } catch (error) {
      console.error("Error during Teams initialization:", error);
    }
  },
  methods: {
    ...mapMutations(["setUserData"]),
    async initializeTeams() {
      await microsoftTeams.app.initialize();
    },
    async handleMsTeamsAuth() {
      const urlParams = new URLSearchParams(window.location.search);
      const msTeamsAuth = urlParams.get("msTeamsAuth");

      if (msTeamsAuth) {
        await this.submitAuthDialog();
      } else if (!this.signedIn) {
        await this.tryAutoLogin();
      }
    },
    async tryAutoLogin() {
      try {
        var cognitoUser = await Auth.signIn(this.userName);
        const challengeAnswer = {
          token: this.authToken,
          tenantId: this.tenantId,
          msTeams: true
        };
        await Auth.sendCustomChallengeAnswer(
          cognitoUser,
          JSON.stringify(challengeAnswer)
        );
        const currentUser = await Auth.currentAuthenticatedUser();
        const teamMemberInfo = await userTeamMemberInformation();
        this.setUserData(teamMemberInfo);
      } catch (error) {
        console.error("Error responding to auth challenge:", error);
        throw error;
      }
    },
    async loadTeamsContext() {
      const context = await microsoftTeams.app.getContext();
      this.appId = context.app.appLaunchId;
      this.userName =
        context.user.displayName ||
        context.user.userPrincipalName ||
        context.user.id;
      this.userId = context.user.id;
      this.tenantId = context.user.tenant.id;
      this.authToken = await microsoftTeams.authentication.getAuthToken();
    },
    openSendAssessmentsDialog() {
      const taskInfo = {
        title: this.$t("msTeams.dashboard.sendAssessmentsDialogTitle"),
        size: { height: "large", width: "large" },
        url: `${
          import.meta.env.VITE_APP_REDIRECT_URL
        }#/ms-teams-assessments-send`
      };
      microsoftTeams.dialog.url.open(taskInfo, async result => {
        try {
          await sendAssessments(result.result);
        } catch (error) {
          console.error("API error: ", error);
        }
      });
    },
    openAuthDialog() {
      const taskInfo = {
        title: this.$t("msTeams.dashboard.authDialogTitle"),
        size: { height: "large", width: "large" },
        url: `${import.meta.env.VITE_APP_REDIRECT_URL}?msTeamsAuth=true`
      };
      microsoftTeams.dialog.url.open(taskInfo, async (result, error) => {
        try {
          if (error) {
            console.error("Dialog error:", error);
            return;
          }
          Auth.Credentials.set(result.tokens);
          await linkMsTeamsUser(this.authToken, this.tenantId);
          window.location.reload();
        } catch (error) {
          console.error("API error: ", error);
        }
      });
    },
    async submitAuthDialog() {
      const tokens = await Auth.currentSession();
      await microsoftTeams.dialog.url.submit(tokens);
    }
  }
};
</script>

<style scoped>
.btn {
  margin-right: 10px;
}
</style>
