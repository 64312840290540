import store from "@/store";
import { Actions } from "@/services/authorization/url-redirect/redirect-constants.js";
import { routes } from "@/router/route-constants.js";

export function handleWellnessRedirect() {
  let redirectObject;
  switch (store.getters.redirectAction?.redirectAction) {
    case Actions.WELLNESS.ALREADY_COMPLETED_DIALOG:
      redirectObject = {
        name: routes.WELLNESS,
        params: {
          alreadyCompletedDialog: true
        }
      };
      break;
    case Actions.WELLNESS.RECOMMENDATION_SECTION:
      redirectObject = {
        name: routes.WELLNESS,
        params: {
          goToSection: Actions.WELLNESS.RECOMMENDATION_SECTION
        }
      };
      break;
    default:
      redirectObject = {
        name: routes.WELLNESS
      };
      break;
  }
  store.commit("setRedirectAction", null);
  return redirectObject;
}
