<template>
  <v-form
    ref="form"
    @submit.prevent
    class="assessment-form-wrapper mb-3"
    data-test-id="assessment-form"
  >
    <div>
      <p v-if="schema.caption" class="text-overline mb-6 text-uppercase">
        {{ $t(schema.caption) }}
      </p>
      <i18n
        v-if="schema.title"
        :path="schema.title"
        tag="h1"
        :class="$vuetify.breakpoint.xs ? 'text-h5 mb-3' : 'text-h4 mb-8'"
      >
        <template v-slot:highlightedText>
          <span class="primary--text">{{ highlightedTitleText }}</span>
        </template>
      </i18n>

      <vfl-callout
        v-if="schema.callout"
        :icon="schema.callout.icon"
        :class="$vuetify.breakpoint.xs ? 'mb-3' : 'mb-8'"
      >
        <template>
          <i18n :path="schema.callout.text" tag="p">
            <template v-if="schema.callout.emphasise" v-slot:emphasise>
              <strong>{{ $t(schema.callout.emphasise) }}</strong>
            </template>
          </i18n>
        </template>
      </vfl-callout>
    </div>

    <div v-if="isPainTheme" class="flex-grow-1">
      <pain-form
        :questions="questions"
        :fullResults="results"
        v-model="internalValue"
        :assessmentType="assessmentType"
      />
    </div>

    <form-generator
      class="assessment-form"
      v-else
      :questions="questions"
      v-model="internalValue"
      @input="next($event)"
      :fullResults="results"
      :groups="groups"
    />
  </v-form>
</template>

<script>
import FormGenerator from "@/components/form/FormGenerator.vue";
import PainForm from "@/components/form/PainForm.vue";
import VflCallout from "@/components/vitrue-foundation-library/VflCallout.vue";

import { scrollToInvalidQuestion } from "@/components/common/assessments/assessment-validation.js";
import { EventBus } from "@/services/events/event-bus";

export default {
  name: "AssessmentForm",
  components: { FormGenerator, PainForm, VflCallout },
  props: {
    schema: Object,
    value: Object,
    isPainTheme: Boolean,
    results: Object,
    highlightedTitleText: String,
    assessmentType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      questions: null,
      groups: null,
      internalValue: this.value
    };
  },
  created() {
    if (this.schema) {
      this.questions = this.schema.questions;
      this.groups = this.schema.formGroups;
    }
  },
  watch: {
    value: {
      handler() {
        this.internalValue = this.value;
      },
      deep: true
    },
    internalValue(newVal) {
      this.$emit("input", newVal);
    }
  },
  methods: {
    isValid() {
      const valid = this.$refs.form.validate();
      if (!valid) {
        EventBus.$emit("validateCustomFormComponents");
        scrollToInvalidQuestion(this, this.$refs.form);
      }
      return valid;
    },
    next(answer) {
      this.$emit("next", answer);
    }
  }
};
</script>

<style lang="scss" scoped>
.assessment-form-wrapper {
  margin: 0 auto;
  max-width: 100%;
  padding-top: 2rem;
}

caption {
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.assessment-form {
  ::v-deep {
    > div + div {
      margin-top: 2rem;
    }
  }
}
</style>
