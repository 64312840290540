<template>
  <div class="vfl-chip" :style="chipStyle">
    <slot name="startDecorator"></slot>
    {{ label }}
    <slot name="endDecorator"></slot>
  </div>
</template>

<script>
export default {
  name: "VflChip",
  props: {
    label: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: "md",
      validator: val => ["sm", "md", "lg"].includes(val)
    },
    backgroundColor: {
      type: String,
      default: null
    },
    borderColor: {
      type: String,
      default: null
    }
  },
  computed: {
    chipStyle() {
      return {
        backgroundColor: this.backgroundColor || null,
        borderColor: this.borderColor || null
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.vfl-chip {
  align-items: center;
  background-color: var(--v-vflBlueVeryLight-base);
  border: 1px solid #dce9f7;
  border-radius: 20px;
  display: inline-flex;
  color: black;
  font-size: 0.75rem;
  line-height: 1;
  padding: 0.3rem 0.75rem;
}
</style>
