import SSOLoginCustomisation from "@/assets/json/common/SSOLoginCustomisation.json";

const BUPA_IDP = "azurebupamain";
const OKTA_IDP_PREFIX = "okta";
const GOOGLE_OIDC_IDP_PREFIX = "googleoidc";
const ONELOGIN_IDP_PREFIX = "onelogin";
const AZURE_IDP_PREFIX = "azure";

export function getSSOCustomisation(idpName) {
  if (!idpName) {
    return null;
  }

  var sanitizedName = idpName?.toLowerCase();
  var customisationObject = null;

  if (sanitizedName == BUPA_IDP) {
    customisationObject = SSOLoginCustomisation.bupa;
  } else if (sanitizedName.includes(OKTA_IDP_PREFIX)) {
    customisationObject = SSOLoginCustomisation.genericOkta;
  } else if (sanitizedName.includes(GOOGLE_OIDC_IDP_PREFIX)) {
    customisationObject = SSOLoginCustomisation.genericGoogle;
  } else if (sanitizedName.includes(ONELOGIN_IDP_PREFIX)) {
    customisationObject = SSOLoginCustomisation.genericOneLogin;
  } else if (sanitizedName.includes(AZURE_IDP_PREFIX)) {
    customisationObject = SSOLoginCustomisation.genericAzure;
  }

  return customisationObject;
}
