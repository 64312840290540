import store from "@/store";
import { assessmentTypes } from "@/constants/constants.js";
import { handleAssessmentRedirect } from "@/services/authorization/url-redirect/assessment-redirect-handler.js";
import { handleWellnessRedirect } from "@/services/authorization/url-redirect/wellness-redirect-handler.js";
import {
  RedirectPages,
  URL_REDIRECT_PAGE_PARAMETER,
  URL_REDIRECT_ACTION_PARAMETER
} from "@/services/authorization/url-redirect/redirect-constants.js";
import { routes } from "@/router/route-constants.js";
import { removeSpecificUrlParameters } from "@/services/url-service.js";
import * as sentry from "@sentry/browser";

const STORE_PAGE_SETTER = "setRedirectPage";
const SESSION_STORAGE_ID = "redirectPage";

export async function handleUrlRedirect() {
  let destination = null;
  try {
    switch (store.getters.redirectPage) {
      case RedirectPages.DESK_ASSESSMENT:
        destination = await handleAssessmentRedirect(
          assessmentTypes.deskAssessment
        );
        break;
      case RedirectPages.WORKSPACE_RESILIENCE_ASSESSMENT:
        destination = await handleAssessmentRedirect(
          assessmentTypes.burnoutAssessment
        );
        break;
      case RedirectPages.DRIVER_ASSESSMENT:
        destination = await handleAssessmentRedirect(
          assessmentTypes.driverAssessment
        );
        break;
      case RedirectPages.PHYSICAL_LABOUR_ASSESSMENT:
        destination = await handleAssessmentRedirect(
          assessmentTypes.physicalLabourAssessment
        );
        break;
      case RedirectPages.WELLNESS:
        destination = handleWellnessRedirect();
        break;
      case RedirectPages.ANALYTICS:
        destination = { name: routes.ANALYTICS };
        break;
      default:
        throw new Error(
          `${store.getters.redirectPage} page is not handled in handleUrlRedirect`
        );
    }
  } catch (err) {
    sentry.captureException(err);
  } finally {
    resetUrlRedirect();
    return destination;
  }
}

export function saveRedirectPageToSessionStorage() {
  if (!store.getters.redirectPage) {
    return;
  }
  sessionStorage.setItem(SESSION_STORAGE_ID, store.getters.redirectPage);
}

export function setUpRedirectPage(queryString = null) {
  let page = getPageFromQueryOrSession(queryString);

  if (!page) {
    return;
  }

  store.commit(STORE_PAGE_SETTER, page);
}

function getPageFromQueryOrSession(queryString) {
  if (queryString && queryString.has(URL_REDIRECT_PAGE_PARAMETER)) {
    return queryString.get("redirectPage");
  }

  let sessionPage = sessionStorage.getItem(SESSION_STORAGE_ID);
  return sessionPage;
}

function resetUrlRedirect() {
  removeSpecificUrlParameters(window.location.href, [
    URL_REDIRECT_PAGE_PARAMETER,
    URL_REDIRECT_ACTION_PARAMETER
  ]);

  store.commit("setRedirectPage", null);
}
