<template>
  <section class="pt-6">
    <h3 class="text-h5 mb-4">{{ $t("dashboardAssessmentCards.title") }}</h3>
    <div class="mr-4 mt-n2 cards-container">
      <div
        class="cards-scroller vfl-scrollbar-hidden d-flex pb-4 pt-8"
        ref="scrollContent"
        :class="{
          'has-gradient-left': !isScrolledFarLeft,
          'has-gradient-right': !isScrolledFarRight
        }"
      >
        <assessment-notification-setting-card />
        <card
          v-for="(card, index) in visibleCards"
          :key="index"
          :first="index === 0"
          v-bind="card"
          :loading="loading"
          v-on="getCardEventHandlers(card)"
        />
      </div>

      <navigation
        @onPrev="scrollLeft"
        @onNext="scrollRight"
        class="nav"
        :disablePrev="isScrolledFarLeft"
        :disableNext="isScrolledFarRight"
      />
    </div>
  </section>
</template>

<script>
import Card from "@/components/dashboard/assessment-cards/DashboardAssessmentCard.vue";
import Navigation from "@/components/dashboard/assessment-cards/DashboardAssessmentCardsNav.vue";
import AssessmentNotificationSettingCard from "@/components/dashboard/AssessmentNotificationSettingCard.vue";
import { mapGetters } from "vuex";
import { startNewAssessment } from "@/customApi";
import * as Constants from "@/constants/constants.js";
import debounce from "lodash/debounce";
import { assessmentTypes } from "@/constants/constants.js";
import {
  DESK_BACKGROUND_GRADIENT,
  Theme,
  Image
} from "@/components/dashboard/assessment-cards/index.js";

const LOCALE_ROOT = "dashboardAssessmentCards";

export default {
  name: "DashboardAssessmentCards",
  components: {
    Card,
    Navigation,
    AssessmentNotificationSettingCard
  },
  data() {
    return {
      isStartingAssessment: false,
      isScrolledFarLeft: true,
      isScrolledFarRight: false
    };
  },
  props: {
    loading: Boolean
  },
  mounted() {
    if (this.$refs?.scrollContent) {
      this.$refs.scrollContent.addEventListener("scroll", this.handleScroll);
    }

    window.addEventListener("resize", this.debouncedResizeListener);

    this.updateNavigationButtonVisibility();
  },
  beforeDestroy() {
    if (this.$refs?.scrollContent) {
      this.$refs.scrollContent.removeEventListener("scroll", this.handleScroll);
    }
    window.removeEventListener("resize", this.debouncedResizeListener);
  },
  computed: {
    ...mapGetters([
      "burnout",
      "disableEmails",
      "disableAssessments",
      "driverAssessmentEnabled",
      "hideBurnoutCompletely",
      "hidePhysicalLabourFromAdminDashboard",
      "isLowerTierSubscription",
      "physicalLabourAssessmentEnabled",
      "userEmail",
      "burnoutFeatureOverride",
      "showDemoAssessmentsInDashboardCards"
    ]),
    showPhysicalLabour() {
      return (
        this.physicalLabourAssessmentEnabled &&
        !this.hidePhysicalLabourFromAdminDashboard
      );
    },
    disableInvite() {
      return this.disableEmails || this.disableAssessments;
    },
    disableDriverInvite() {
      return this.disableInvite || this.isLowerTierSubscription;
    },
    disablePhysicalLabourInvite() {
      return this.disableInvite || this.isLowerTierSubscription;
    },
    disableResilienceInvite() {
      return (
        this.disableInvite ||
        (this.isLowerTierSubscription && !this.burnoutFeatureOverride)
      );
    },
    lockDriver() {
      return this.isLowerTierSubscription || this.disableAssessments;
    },
    lockPhysicalLabour() {
      return this.isLowerTierSubscription || this.disableAssessments;
    },
    lockResilience() {
      return (
        (this.isLowerTierSubscription && !this.burnoutFeatureOverride) ||
        this.disableAssessments
      );
    },
    visibleCards() {
      const cards = [
        {
          actionCardType: assessmentTypes.deskAssessment,
          color: Theme.DESK,
          css: {
            backgroundImage: DESK_BACKGROUND_GRADIENT
          },
          description: `${LOCALE_ROOT}.desk.description`,
          disableInvite: this.disableInvite,
          disableInviteTooltipText: this.getTooltipText("desk", "invite"),
          disableTryNow: this.disableAssessments,
          disableTryNowTooltipText: this.getTooltipText("desk", "try"),
          image: Image.DESK,
          onboardingTooltips: true,
          show: true,
          title: `${LOCALE_ROOT}.desk.title`
        },
        {
          actionCardType: assessmentTypes.driverAssessment,
          color: Theme.DRIVER,
          description: `${LOCALE_ROOT}.driver.description`,
          disableInvite: this.disableDriverInvite,
          disableInviteTooltipText: this.getTooltipText("driver", "invite"),
          disableTryNow: this.lockDriver,
          disableTryNowTooltipText: this.getTooltipText("driver", "try"),
          image: Image.DRIVER,
          show: this.driverAssessmentEnabled,
          title: `${LOCALE_ROOT}.driver.title`
        },
        {
          actionCardType: assessmentTypes.physicalLabourAssessment,
          color: Theme.PHYSICAL,
          description: `${LOCALE_ROOT}.physicalLabour.description`,
          disableInvite: this.disablePhysicalLabourInvite,
          disableInviteTooltipText: this.getTooltipText(
            "physicalLabour",
            "invite"
          ),
          disableTryNow: this.lockPhysicalLabour,
          disableTryNowTooltipText: this.getTooltipText(
            "physicalLabour",
            "try"
          ),
          image: Image.PHYSICAL,
          show: this.showPhysicalLabour,
          title: `${LOCALE_ROOT}.physicalLabour.title`
        },
        {
          actionCardType: assessmentTypes.burnoutAssessment,
          color: Theme.RESILIENCE,
          description: `${LOCALE_ROOT}.resilience.description`,
          disableInvite: this.disableResilienceInvite,
          disableInviteTooltipText: this.getTooltipText("resilience", "invite"),
          disableTryNow: this.lockResilience,
          disableTryNowTooltipText: this.getTooltipText("resilience", "try"),
          image: Image.RESILIENCE,
          show: !this.hideBurnoutCompletely,
          title: `${LOCALE_ROOT}.resilience.title`
        },
        {
          actionCardType: "demo",
          color: Theme.NEW_PARENTS,
          image: Image.NEW_PARENTS,
          show: this.showDemoAssessmentsInDashboardCards,
          title: `New parents<br/>return to work`
        },
        {
          actionCardType: "demo",
          color: Theme.AIRPLANE,
          image: Image.AIRPLANE,
          show: this.showDemoAssessmentsInDashboardCards,
          title: `Long distance<br/>air travel`
        },
        {
          actionCardType: "demo",
          color: Theme.MENSTRUATION,
          image: Image.MENSTRUATION,
          show: this.showDemoAssessmentsInDashboardCards,
          title: `Menstruation<br/>and MSK pain`
        },
        {
          actionCardType: "demo",
          color: Theme.MENOPAUSE,
          image: Image.MENOPAUSE,
          show: this.showDemoAssessmentsInDashboardCards,
          title: `Menopause<br/>and MSK pain`
        }
      ];

      return cards.filter(card => card.show);
    }
  },
  methods: {
    async startNewAssessmentForSimpleUser(assessmentType) {
      if (this.isStartingAssessment) return;

      try {
        this.isStartingAssessment = true;

        const invitation = {
          email: { value: this.userEmail },
          assessmentType: assessmentType
        };

        let assessmentId = await startNewAssessment(invitation);
        const paths = Constants.assessmentPaths;
        const url = `/${paths[assessmentType]}/${assessmentId}`;

        await this.$router.push(url);
      } finally {
        this.isStartingAssessment = false;
      }
    },
    onActionCardClicked(assessmentType) {
      this.$emit("onAssessmentCardClicked", assessmentType);
    },
    handleScroll() {
      this.updateNavigationButtonVisibility();
    },
    updateNavigationButtonVisibility() {
      const container = this.$refs.scrollContent;
      if (!container) return;

      this.isScrolledFarRight =
        container.scrollLeft === container.scrollWidth - container.clientWidth;

      this.isScrolledFarLeft = container.scrollLeft === 0;
    },
    scrollLeft() {
      const container = this.$refs.scrollContent;

      if (container) {
        container.scrollLeft -= 320;

        this.updateNavigationButtonVisibility();
      }
    },
    scrollRight() {
      const container = this.$refs.scrollContent;

      if (container) {
        container.scrollLeft += 320;

        this.updateNavigationButtonVisibility();
      }
    },
    debouncedResizeListener: debounce(function () {
      this.handleResize();
    }, 200),
    handleResize() {
      this.updateNavigationButtonVisibility();
    },
    getTooltipText(assessmentType, action) {
      if (action === "invite") {
        return this.getInviteTooltipText(assessmentType);
      } else if (action === "try") {
        return this.getTryNowTooltipText(assessmentType);
      } else {
        this.$logger.captureMessage(
          "Unknown action in dashboard assessment card tooltip",
          action
        );
      }
    },
    getInviteTooltipText(assessmentType) {
      if (this.disableEmails) {
        return "tooltips.disabledByAdmin";
      } else if (this.isLowerTierSubscription && assessmentType !== "desk") {
        return `${LOCALE_ROOT}.${assessmentType}.tooltip`;
      } else {
        return "tooltips.trialOrSubHasExpired";
      }
    },
    getTryNowTooltipText(assessmentType) {
      if (this.isLowerTierSubscription && assessmentType !== "desk") {
        return `${LOCALE_ROOT}.${assessmentType}.tooltip`;
      } else {
        return "tooltips.trialOrSubHasExpired";
      }
    },
    getCardEventHandlers(card) {
      if (card.actionCardType === "demo") {
        return {};
      }

      return {
        invite: () => this.onActionCardClicked(card.actionCardType),
        tryNow: () => this.startNewAssessmentForSimpleUser(card.actionCardType)
      };
    }
  }
};
</script>

<style lang="scss" scoped>
section {
  container-type: inline-size;

  // Hide the image at smaller container sizes
  // Use container instead of window to account for sidebar open/closed state
  @container (width < 600px) {
    ::v-deep .image {
      display: none !important;
    }
  }
}

.cards-container {
  position: relative;

  &:hover {
    .nav {
      opacity: 1;
    }
  }
}

.cards-scroller {
  gap: 1.5rem;
  overflow-x: auto;
  scroll-behavior: smooth;

  &:before,
  &:after {
    bottom: 0;
    content: "";
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 50px;
    z-index: 1;
  }

  &.has-gradient-left:before {
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0), white);
    opacity: 1;
    left: -1px;
  }

  &.has-gradient-right:after {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), white);
    opacity: 1;
    right: -1px;
  }
}
</style>
