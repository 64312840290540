import { assessmentTypes } from "@/constants/constants.js";
import DriverAssessmentSchema from "@/assets/json/DriverAssessment/questions.json";
import PhysicalLabourAssessmentSchema from "@/components/assessment/physical-labour/questionSchema.json";
import PreExistingConditionsSchema from "@/assets/json/pre-existing-conditions.json";
import DeskAssessmentSchema from "@/assets/json/DeskAssessment/DeskAssessmentQuestions.json";
import sentry from "@/sentry";
import store from "@/store";
import { cloneDeep } from "lodash";

const ErrorMessages = {
  INVALID_SCHEMA_TYPE: "schemaA and schemaBs must both be arrays",
  MISSING_MERGE_PROPERTIES: "Missing properties required for schema merge",
  THEME_NOT_FOUND: theme => `No entry in schemaA with theme '${theme}' found`,
  NO_PAGE_SPECIFIED: theme =>
    `'pages' are present in theme '${theme}', but no 'page' specified for merging`,
  PAGE_NOT_FOUND: (page, theme) =>
    `Page named '${page}' not found within theme '${theme}'`,
  NO_QUESTIONS_ARRAY: page => `No 'questions' array found in page '${page}'`,
  INSERT_AFTER_NOT_FOUND: insertAfter =>
    `'insertAfter' target not found: ${insertAfter}`,
  PAIN_AREA_NOT_FOUND: painArea =>
    `Matching pain area '${painArea}' not found in questions`
};

export const getSchema = assessmentType => {
  switch (assessmentType) {
    case assessmentTypes.driverAssessment:
      return getDriverAssessmentSchema();
    case assessmentTypes.physicalLabourAssessment:
      return getPhysicalLabourAssessmentSchema();
    case assessmentTypes.deskAssessment:
      return getDeskAssessmentSchema();
  }
};

function getDriverAssessmentSchema() {
  const schema = cloneDeep(DriverAssessmentSchema);

  if (!store.getters.enablePreExistingConditions) {
    return schema;
  } else {
    return mergeSchemas(
      schema,
      PreExistingConditionsSchema,
      assessmentTypes.driverAssessment
    );
  }
}

function getPhysicalLabourAssessmentSchema() {
  return PhysicalLabourAssessmentSchema;
}

function getDeskAssessmentSchema() {
  const schema = cloneDeep(DeskAssessmentSchema);

  if (!store.getters.enablePreExistingConditions) {
    return schema;
  } else {
    return mergeSchemas(
      schema,
      PreExistingConditionsSchema,
      assessmentTypes.deskAssessment
    );
  }
}

function mergeSchemas(schemaA, schemaBs, assessmentType = null) {
  if (!Array.isArray(schemaA) || !Array.isArray(schemaBs)) {
    handleError(ErrorMessages.INVALID_SCHEMA_TYPE);
    return schemaA;
  }

  const schemasToMerge = assessmentType
    ? schemaBs.filter(schemaB => {
        if (!schemaB.merge?.assessmentTypes) {
          return true;
        }

        return schemaB.merge.assessmentTypes.some(
          type => type === assessmentType
        );
      })
    : schemaBs;

  schemasToMerge.forEach(schemaB => {
    if (!schemaB.merge || !schemaB.merge.theme || !schemaB.merge.insertAfter) {
      handleError(ErrorMessages.MISSING_MERGE_PROPERTIES);
      return;
    }

    const {
      merge: { theme, page, insertAfter, painAreas },
      ...newQuestion
    } = schemaB;

    const themeToUpdate = schemaA.find(item => item.theme === theme);

    if (!themeToUpdate) {
      handleError(ErrorMessages.THEME_NOT_FOUND(theme));
      return;
    }

    let targetQuestions;

    if (themeToUpdate.pages) {
      if (!page) {
        handleError(ErrorMessages.NO_PAGE_SPECIFIED(theme));
        return;
      }

      const pageToUpdate = themeToUpdate.pages.find(p => p.name === page);

      if (!pageToUpdate) {
        handleError(ErrorMessages.PAGE_NOT_FOUND(page, theme));
        return;
      }

      targetQuestions = pageToUpdate.questions;
    } else {
      targetQuestions = themeToUpdate.questions;
    }

    if (Array.isArray(targetQuestions)) {
      const newQuestionClone = cloneDeep(newQuestion);
      insertQuestion(targetQuestions, insertAfter, newQuestionClone);
    } else {
      processPainQuestion(targetQuestions, insertAfter, newQuestion, painAreas);
    }
  });

  return schemaA;
}

function processPainQuestion(questions, insertAfter, newQuestion, painAreas) {
  if (painAreas && painAreas.length) {
    painAreas.forEach(painArea => {
      if (!questions[painArea]) {
        handleError(ErrorMessages.PAIN_AREA_NOT_FOUND(painArea));
        return;
      }

      const newQuestionClone = cloneDeep(newQuestion);
      insertQuestion(questions[painArea], insertAfter, newQuestionClone);
    });
  }
}

function insertQuestion(questions, insertAfter, newQuestion) {
  const alreadyMerged = questions.some(q => q.name === newQuestion.name);
  if (alreadyMerged) return;

  const index = questions.findIndex(q => q.name === insertAfter);

  if (index !== -1) {
    questions.splice(index + 1, 0, newQuestion);
  } else {
    handleError(ErrorMessages.INSERT_AFTER_NOT_FOUND(insertAfter));
  }
}

function handleError(message) {
  if (import.meta.env.DEV) {
    console.error(message);
  } else {
    sentry.captureMessage(message, "error");
  }
}
