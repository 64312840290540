export const deskAssessmentInsightCategories = [
    "posture",
    "habit",
    "setup",
    "health"
  ],
  assessmentTypes = {
    deskAssessment: "Desk assessment",
    dailyFeedback: "Pain coach daily feedback",
    checkIn: "Pain check in",
    burnoutAssessment: "Burnout assessment",
    driverAssessment: "Driver assessment",
    physicalLabourAssessment: "Physical labour assessment",
    emailPainCheckIn: "Email pain check in",
    verifiedEmailPainCheckIn: "Verified email pain check in"
  },
  assessmentPaths = {
    [assessmentTypes.deskAssessment]: "deskassessment",
    [assessmentTypes.burnoutAssessment]: "work-resilience-assessment",
    [assessmentTypes.driverAssessment]: "driver-assessment",
    [assessmentTypes.physicalLabourAssessment]: "physical-labour-assessment"
  },
  assessmentBaseUrls = {
    [assessmentTypes.deskAssessment]: `${window.location.origin}/#/deskassessment`,
    [assessmentTypes.burnoutAssessment]: `${window.location.origin}/#/work-resilience-assessment`,
    [assessmentTypes.driverAssessment]: `${window.location.origin}/#/driver-assessment`,
    [assessmentTypes.physicalLabourAssessment]: `${window.location.origin}/#/physical-labour-assessment`
  },
  assessmentReportIds = {
    [assessmentTypes.deskAssessment]: "deskreport",
    [assessmentTypes.burnoutAssessment]: "burnoutreport",
    [assessmentTypes.driverAssessment]: "driverreport",
    [assessmentTypes.physicalLabourAssessment]: "physicallabourreport"
  },
  assessmentLocalePaths = {
    [assessmentTypes.deskAssessment]: "desk",
    [assessmentTypes.burnoutAssessment]: "burnout",
    [assessmentTypes.driverAssessment]: "driver",
    [assessmentTypes.physicalLabourAssessment]: "physical"
  },
  WorkSpacePriority = {
    MAX: 1,
    HIGH: 2,
    MEDIUM: 3,
    LOW: 4
  };

export const SuggestionsPrioritySortOrder = {
  noKeyboard: 0,
  noMouse: 1,
  noLaptopStand: 2,
  noMonitor: 3,
  noLaptopStandOrScreen: 4,
  screenTooClose: 5,
  noBackSupport: 6,
  feetNotFlat: 7,
  noHeadset: 8,
  armRestsNotCloseEnoughToDesk: 9,
  noChair: 10,
  seatNotAdjustable: 11,
  noDesk: 12,
  deskTooLow: 13,
  noStandingDesk: 14,
  noLunchOrBreaks: 15,
  noWater: 16,
  noPlants: 17,
  insufficientLighting: 18,
  longHours: 19,
  noNaturalLightInOffice: 20
};

export const DeskAssessmentGoals = {
  FIX_POSTURE: "FixPosture",
  IMPROVE_WELLBEING: "ImproveWellbeing",
  REDUCE_PAIN: "ReducePain"
};

export const AdminRoles = {
  BUSINESS_OWNER: "business_owner",
  DIRECTOR: "director",
  HEAD_OF_HUMAN_RESOURCES: "head_of_human_resources",
  HUMAN_RESOURCES: "human_resources",
  HEALTH_AND_SAFETY: "health_and_safety",
  OFFICE_MANAGEMENT: "office_management",
  OPERATIONS_MANAGEMENT: "operations_management",
  LINE_MANAGEMENT: "line_management",
  REWARD_AND_BENEFITS: "reward_and_benefits",
  TEAM_LEADER: "team_leader",
  WELLBEING: "wellbeing",
  OTHER: "other"
};

export const AdminRolesToTextKeyMapping = {
  [AdminRoles.BUSINESS_OWNER]: "businessOwner",
  [AdminRoles.DIRECTOR]: "director",
  [AdminRoles.HEAD_OF_HUMAN_RESOURCES]: "headOfHR",
  [AdminRoles.HUMAN_RESOURCES]: "humanResources",
  [AdminRoles.HEALTH_AND_SAFETY]: "healthAndSafety",
  [AdminRoles.OFFICE_MANAGEMENT]: "officeManagement",
  [AdminRoles.OPERATIONS_MANAGEMENT]: "operationsManagement",
  [AdminRoles.LINE_MANAGEMENT]: "lineManagement",
  [AdminRoles.REWARD_AND_BENEFITS]: "rewardAndBenefits",
  [AdminRoles.TEAM_LEADER]: "teamLeader",
  [AdminRoles.WELLBEING]: "wellbeing",
  [AdminRoles.OTHER]: "other"
};
