<template>
  <v-expansion-panels flat multiple accordion tile v-model="painAreaOpenPanels">
    <v-expansion-panel
      v-for="area in currentPainAreas"
      :key="area"
      class="mb-2 pa-0"
    >
      <v-expansion-panel-header class="py-0 pl-2" color="vitruePaleGrey">
        <v-row class="py-0" no-gutters justify="space-between"
          ><v-col :id="`${area}Name`" class="text-h6">
            <v-row no-gutters justify="start">
              <v-col cols="auto"
                ><div class="mr-2" style="width: 24px">
                  <v-icon
                    v-if="isAreaCompleted(area)"
                    :id="`${area}CompleteIcon`"
                    color="secondary"
                    >mdi-check</v-icon
                  >
                </div></v-col
              >
              <v-col> {{ getNameFromSvgRegion(area) }}</v-col>
            </v-row>
          </v-col>
          <v-col cols="auto">
            <v-btn
              :id="`${area}DeleteButton`"
              class="mr-2"
              icon
              small
              @click="
                event => {
                  removePanel(event, area);
                }
              "
            >
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn></v-col
          ></v-row
        >
      </v-expansion-panel-header>
      <v-expansion-panel-content class="pt-4">
        <form-generator
          :questions="questions[area]"
          v-model="results['pain'].areas[area]"
          :fullResults="results"
          :key="`${area}Form`"
          enforceNumbering
          :class="formClasslist"
          :group="area"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import svgRegionsMale from "@/components/painareas/PainAreasMale.json";
import FormGenerator from "@/components/form/FormGenerator.vue";
import Vue from "vue";
import { assessmentTypes } from "@/constants/constants.js";

const painTheme = "pain";

export default {
  components: {
    FormGenerator
  },
  props: {
    questions: Object,
    results: Object,
    assessmentType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      painAreaOpenPanels: []
    };
  },
  watch: {
    currentPainAreas: {
      handler(newVal, oldVal) {
        if (newVal.length > oldVal.length) {
          let newAreas = newVal.filter(x => !oldVal.includes(x));
          newAreas.forEach(a => {
            this.openPainAreaPanelContent(a);
          });
        } else if (newVal.length < oldVal.length) {
          let areasToRemove = oldVal.filter(x => newVal.includes(x));
          areasToRemove.forEach(a => this.hidePainAreaPanelContent(a));
        }
      },
      deep: true
    }
  },
  computed: {
    currentPainAreas() {
      return Object.keys(this.results.pain.areas);
    },
    formClasslist() {
      return this.assessmentType === assessmentTypes.deskAssessment
        ? "legacy-pain-layout"
        : "";
    }
  },
  methods: {
    isAreaCompleted(area) {
      const visibleQuestions = this.questions[area].filter(q => {
        return (
          (q.show === undefined || q.show === true) && !q.optional && q.question
        );
      });

      if (visibleQuestions.length === 0) {
        return true;
      }

      return visibleQuestions.every(q => {
        switch (q.name) {
          case "level":
            return !!this.results[painTheme].areas[area][q.name];
          default:
            return this.results[painTheme].areas[area][q.name] !== undefined;
        }
      });
    },
    getNameFromSvgRegion(area) {
      return this.$t(svgRegionsMale[area].name);
    },
    openPainAreaPanelContent(area) {
      let indexOfArea = this.currentPainAreas.indexOf(area);
      this.painAreaOpenPanels.push(indexOfArea);
    },
    hidePainAreaPanelContent(area) {
      // reduce any values that are open by 1 which are past the one we are removing
      let indexOfArea = this.currentPainAreas.indexOf(area);
      var updatedValues = this.painAreaOpenPanels.map(i => {
        return i > indexOfArea ? i - 1 : i;
      });
      updatedValues.splice(indexOfArea, 1);
      Vue.nextTick(() => {
        this.painAreaOpenPanels = [...updatedValues];
      });
    },
    allAreasComplete() {
      return this.currentPainAreas.every(x => this.isAreaCompleted(x));
    },
    showIncompletePanels() {
      let newPanelStates = [];
      for (let i = 0; i < this.currentPainAreas.length; i++) {
        let currentArea = this.currentPainAreas[i];
        if (!this.isAreaCompleted(currentArea)) {
          newPanelStates.push(i);
        }
      }
      this.painAreaOpenPanels = [...newPanelStates];
    },
    removePanel(event, area) {
      event.stopPropagation(); // need to stop event propagation in order to prevent panel from collapsing/opening

      this.$emit("removePainArea", area);
    }
  }
};
</script>

<style lang="scss" scoped>
.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 42px;
}

.v-expansion-panel-header {
  min-height: 42px;
}

::v-deep .v-expansion-panel-content__wrap {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.legacy-pain-layout {
  ::v-deep .form-question + .form-question {
    margin-top: 0 !important;
  }
}
</style>
