<template>
  <div class="d-flex align-center">
    <FeatureFlagSelector v-if="showFeatureFlagSelector" />
    <notifications-badge v-if="showNotifications" />
    <account-menu class="ml-5" />
  </div>
</template>

<script>
import FeatureFlagSelector from "@/components/internal/FeatureFlagSelector.vue";
import NotificationsBadge from "@/components/common/NotificationBadge.vue";
import { mapGetters } from "vuex";
import AccountMenu from "@/components/layout/AccountMenu.vue";

export default {
  name: "PageHeaderActions",
  components: { AccountMenu, NotificationsBadge, FeatureFlagSelector },
  data() {
    return {
      showFeatureFlagSelector: false
    };
  },
  computed: {
    ...mapGetters(["signedIn", "isAdminUser"]),
    showNotifications() {
      return this.signedIn && this.isAdminUser;
    }
  }
};
</script>

<style lang="scss" scoped></style>
