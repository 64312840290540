import * as Constants from "@/constants/constants.js";

export const routes = {
  LOGIN: "Login",
  SIGN_UP: "SignUp",
  RESET_PASSWORD: "ResetPassword",
  INVITATION_SIGN_UP: "InvitationSignUp",
  SHAREABLE_LINK: "ShareableLink",
  SHAREABLE_LINK_SOME_DETAILS: "ShareableLinkSomeDetails",
  SHAREABLE_LINK_ALL_DETAILS: "ShareableLinkAllDetails",
  VISUAL_DESK_PLANNER: "VisualDeskPlanner",
  DASHBOARD: "Dashboard",
  ANALYTICS: "Analytics",
  SUGGESTIONS: "Suggestions",
  USERS: "Users",
  ACCOUNT: "Account",
  SETTINGS: "Settings",
  PERSONAL_SETTINGS: "PersonalSettings",
  SUBSCRIPTION: "Subscription",
  DESK_ASSESSMENT: "DeskAssessment",
  BURNOUT_ASSESSMENT: "BurnoutAssessment",
  COGNITIVE_ASSESSMENT: "CognitiveAssessment",
  DRIVER_ASSESSMENT: "DriverAssessment",
  PHYSICAL_LABOUR_ASSESSMENT: "PhysicalLabourAssessment",
  WELLNESS: "Wellness",
  REPORTS_ARCHIVE: "ReportsArchive",
  JOIN_TEAM: "JoinTeam",
  CREATE_TEAM: "CreateTeam",
  CHOOSE_TEAM: "ChooseTeam",
  CERTIFICATION: "Certification",
  FEEDBACK: "Feedback",
  EMAIL_PAIN_CHECK_IN: "EmailPainCheckIn",
  DEV_PAGE: "DevPage",
  SLACK_DEV_PAGE: "SlackDevPage",
  CLINICAL_PAGE: "ClinicalPage",
  ERROR_PAGE: "ErrorPage",
  NOT_FOUND: "404",
  SCORM: "Scorm",
  PEOPLE: "People",
  MS_TEAMS_DASHBOARD: "MsTeams"
};

export const assessmentRoutes = {
  [Constants.assessmentTypes.deskAssessment]: "DeskAssessment",
  [Constants.assessmentTypes.burnoutAssessment]: "BurnoutAssessment",
  [Constants.assessmentTypes.driverAssessment]: "DriverAssessment",
  [Constants.assessmentTypes.physicalLabourAssessment]:
    "PhysicalLabourAssessment"
};
