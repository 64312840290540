<template>
  <div class="pills-group" role="group">
    <label
      v-for="option in processedOptions"
      :key="option.value"
      class="pill"
      :class="{ 'pill-selected': isSelected(option.value) }"
    >
      <input
        type="checkbox"
        :value="option.value"
        :checked="isSelected(option.value)"
        @change="toggleOption(option.value)"
      />
      <span>{{ option.label }}</span>
    </label>
  </div>
</template>

<script>
import Answer from "@/components/common/assessments/answer-constants";

export default {
  name: "CheckBoxPillsQuestion",
  props: {
    options: {
      type: Array,
      required: true
    },
    value: {
      type: Array,
      default: () => []
    },
    processOptionLabels: {
      type: Boolean,
      default: true
    },
    hasNoneOption: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedInternal: [...this.value]
    };
  },
  computed: {
    processedOptions() {
      if (!this.processOptionLabels) return this.options;

      return this.options.map(option => ({
        ...option,
        label: this.$t(option.label)
      }));
    }
  },
  methods: {
    isSelected(value) {
      return this.selectedInternal.includes(value);
    },
    toggleOption(value) {
      let newSelection = [];

      if (value === Answer.NONE_ANSWER_VALUE) {
        // If "None" is selected, clear other selections
        newSelection = this.isSelected(value) ? [] : [value];
      } else {
        // If another option is selected, remove "None" if it was selected
        newSelection = [...this.selectedInternal];
        const index = newSelection.indexOf(value);

        if (index === -1) {
          // Add the new value and remove "None" if present
          newSelection.push(value);
          const noneIndex = newSelection.indexOf(Answer.NONE_ANSWER_VALUE);
          if (noneIndex !== -1) {
            newSelection.splice(noneIndex, 1);
          }
        } else {
          // Remove the value
          newSelection.splice(index, 1);
        }
      }

      this.selectedInternal = newSelection;
      this.$emit("input", newSelection);
    }
  },
  watch: {
    value: {
      handler(newVal) {
        this.selectedInternal = [...newVal];
      },
      deep: true
    }
  }
};
</script>

<style scoped lang="scss">
.pills-group {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.pill {
  background-color: white;
  border: 1px solid #d0d0d0;
  border-radius: 999px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 40px;
  min-width: 140px;
  padding: 0 1.5rem;
  user-select: none;

  &:hover:not(.pill-selected) {
    background-color: color-mix(in srgb, var(--v-vflPrimary-base), #fff 98%);
  }

  &:focus-within {
    outline: 2px solid var(--v-vflPrimary-base);
    outline-offset: -1px;
  }
}

/* Removes the checkbox tick */
.pill input[type="checkbox"] {
  border: 0 !important;
  clip: rect(0, 0, 0, 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

.pill-selected {
  background-color: color-mix(in srgb, var(--v-vflPrimary-base), #fff 95%);
  outline: 2px solid var(--v-vflPrimary-base);
  outline-offset: -1px;
}
</style>
