<template>
  <div
    v-if="!isLoading"
    class="d-flex align-center team-logo-and-name"
    :class="isDark ? 'is-dark' : ''"
  >
    <div v-if="teamName || teamLogoURL" class="d-flex justify-center">
      <button
        class="text-button-reset"
        v-if="teamName && (!teamLogoURL || imageLoadError)"
        @click="clickIcon()"
        :disabled="!isSuperAdmin"
      >
        <initials-icon
          :text="teamName"
          color="3B9698"
          TextColor="FFFFFF"
          :size="`${logoSize}px`"
          defaultIcon="mdi-office-building-outline"
        />
      </button>
      <div
        v-else-if="teamLogoURL && !imageLoadError"
        class="image-crop-container team-logo"
        :style="`position: relative;text-align: center;width: ${logoSize}px;height: ${logoSize}px;`"
      >
        <img
          :src="teamLogoURL"
          style="margin: auto; display: block"
          @error="handleImageError"
        />
      </div>
    </div>

    <h5 v-if="showTeamName" class="team-name ml-3" :title="teamName">
      {{ teamName }}
    </h5>
  </div>
  <div v-else class="d-flex align-center">
    <v-skeleton-loader
      type="avatar"
      :width="logoSize"
      :height="logoSize"
      class="skeleton-loader-avatar"
    />
    <v-skeleton-loader
      v-if="variant === 'full'"
      type="heading"
      class="skeleton-loader-heading ml-4"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { getTeamDetails } from "@/customApi.js";
import InitialsIcon from "./InitialsIcon.vue";

export default {
  name: "TeamLogoAndName",
  components: {
    InitialsIcon
  },
  props: {
    variant: {
      type: String,
      validator: value => ["minimal", "full"].includes(value),
      default: "full"
    },
    isDark: false
  },
  data() {
    return {
      isLoading: false,
      imageLoadError: false,
      imageLoadReattempt: false
    };
  },
  created() {
    this.getTeamDetails();
  },
  computed: {
    ...mapGetters(["signedIn", "isSuperAdmin", "teamName", "teamLogoURL"]),
    logoSize() {
      if (this.variant === "minimal") return 32;

      return 40;
    },
    showTeamName() {
      return this.teamName && this.variant === "full";
    }
  },
  methods: {
    ...mapMutations(["setTeamName", "setTeamLogoURL"]),
    clickIcon() {
      if (this.$route.name === "Settings") return;

      this.$router.push({
        name: "Settings",
        params: { tabToShow: "workspacesettings" }
      });
    },
    async getTeamDetails() {
      if (this.teamName && this.teamLogoURL) return;

      this.isLoading = true;

      try {
        const teamDetails = await getTeamDetails();

        this.setTeamName(teamDetails.name);
        this.setTeamLogoURL(teamDetails.logoURL);
      } catch (err) {
        this.$logger.captureException(err);
      } finally {
        this.isLoading = false;
      }
    },
    async handleImageError(e) {
      this.$logger.captureMessage(
        `Failed to load team logo image - logo url ${this.teamLogoURL}`
      );

      if (this.imageLoadReattempt) {
        this.imageLoadError = true;
        return;
      }

      try {
        await this.getTeamDetails();
      } catch (err) {
        this.imageLoadError = true;
        this.$logger.captureException(err);
      } finally {
        this.imageLoadReattempt = true;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.team-logo {
  border-radius: 4px;
}

.hideOverflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.team-name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.2;
  color: white;
  word-break: break-word;
}

.is-dark {
  .team-name {
    color: var(--v-vflTextDark-base);
  }
}

.skeleton-loader-heading {
  flex-grow: 1;
}

::v-deep {
  .v-skeleton-loader__avatar {
    height: 100%;
    width: 100%;
  }

  .v-skeleton-loader__heading {
    height: 16px;
    width: 100%;
  }
}
</style>
