<template>
  <div>
    <h3>{{ $t("msTeams.sendAssessment.title") }}</h3>
    <form @submit.prevent="submitAssessment" id="assessmentForm">
      <div class="form-group">
        <label for="type">{{
          $t("msTeams.sendAssessment.form.typeLabel")
        }}</label>
        <select id="type" class="form-control" v-model="assessmentType">
          <option
            :value="$t('msTeams.sendAssessment.form.typeOptions.homeDesk')"
          >
            {{ $t("msTeams.sendAssessment.form.typeOptions.homeDesk") }}
          </option>
          <option
            :value="$t('msTeams.sendAssessment.form.typeOptions.officeDesk')"
          >
            {{ $t("msTeams.sendAssessment.form.typeOptions.officeDesk") }}
          </option>
          <option
            :value="$t('msTeams.sendAssessment.form.typeOptions.drivers')"
          >
            {{ $t("msTeams.sendAssessment.form.typeOptions.drivers") }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label>{{ $t("msTeams.sendAssessment.form.recipientsLabel") }}</label>
        <ul class="list-group">
          <li
            v-for="(person, index) in selectedPeople"
            :key="index"
            class="list-group-item"
          >
            {{ person.displayName }} ({{ person.email }})
          </li>
        </ul>
        <button
          type="button"
          class="btn btn-secondary mt-2"
          @click="openPeoplePicker"
        >
          {{ $t("msTeams.sendAssessment.form.addPeopleButton") }}
        </button>
      </div>
      <button type="submit" class="btn btn-primary mt-3">
        {{ $t("msTeams.sendAssessment.form.submitButton") }}
      </button>
    </form>
  </div>
</template>

<script>
import * as microsoftTeams from "@microsoft/teams-js";

export default {
  name: "MsTeamsSendAssessmentDialog",
  data() {
    return {
      assessmentType: "Desk assessment",
      selectedPeople: [],
      appId: ""
    };
  },
  async mounted() {
    try {
      await microsoftTeams.app.initialize();
      const context = await microsoftTeams.app.getContext();
      this.appId = context.app.appLaunchId;
    } catch (error) {
      console.error("Error initializing Teams:", error);
    }
  },
  methods: {
    async openPeoplePicker() {
      try {
        const selectedPeople = await microsoftTeams.people.selectPeople({
          openOrgWideSearchInChatOrChannel: false,
          singleSelect: false
        });
        if (selectedPeople && selectedPeople.length > 0) {
          this.selectedPeople = selectedPeople;
        }
      } catch (error) {
        console.error("Error selecting people:", error);
      }
    },
    submitAssessment() {
      const assessmentData = {
        assessmentType: this.assessmentType,
        recipients: this.selectedPeople.map(person => ({
          id: person.objectId,
          email: person.email,
          name: person.displayName
        }))
      };
      microsoftTeams.dialog.url.submit(assessmentData, this.appId);
    }
  }
};
</script>
<style scoped>
.btn {
  margin-right: 10px;
}
</style>
