<template>
  <div>
    <p v-if="showLabels" class="d-md-none font-weight-bold mb-2 text-small">
      <span class="mr-1">{{ `${currentStep}/${stepsArray.length}` }}</span>
      {{ stepsArray[currentStep - 1] }}
    </p>
    <ol class="pl-0 d-flex">
      <template v-for="(step, index) in stepsArray">
        <li
          :key="`${index}-step`"
          :class="[
            { 'is-complete': currentStep > index + 1 },
            { 'is-current': currentStep === index + 1 },
            { 'is-mobile': $vuetify.breakpoint.smAndDown },
            sizeClass
          ]"
          class="d-flex flex-grow-1"
          :data-index="`${index + 1}/${stepsArray.length}`"
        >
          <span v-if="currentStep > index + 1" class="d-sr-only"
            >{{ $t("vfl.stepper.complete") }}{{ showLabels ? `: ${step}` : "" }}
          </span>
          <span v-if="currentStep === index + 1" class="d-sr-only"
            >{{ $t("vfl.stepper.current") }}{{ showLabels ? `: ${step}` : "" }}
          </span>

          <span v-if="showLabels" class="label d-flex flex-column">{{
            step
          }}</span>
          <span v-else class="label d-flex flex-column"></span>
        </li>
      </template>
    </ol>
  </div>
</template>

<script>
export default {
  name: "VflStepper",
  props: {
    steps: {
      type: [Array, Number],
      required: true
    },
    currentStep: {
      type: [String, Number],
      default: 1
    },
    size: {
      type: String,
      default: "large",
      validator: value => ["small", "large"].includes(value)
    }
  },
  data() {
    return {
      internalCurrentStep: this.currentStep
    };
  },
  computed: {
    stepsArray() {
      if (Array.isArray(this.steps)) {
        return this.steps;
      } else {
        return Array.from({ length: this.steps }, (_, i) => `Step ${i + 1}`);
      }
    },
    showLabels() {
      return Array.isArray(this.steps);
    },
    sizeClass() {
      return this.size === "small" ? "is-small" : "is-large";
    }
  },
  watch: {
    currentStep(newVal) {
      this.internalCurrentStep = newVal;
    },
    internalCurrentStep(newVal) {
      if (newVal !== this.currentStep) {
        this.$emit("update:currentStep", newVal);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
ol {
  gap: 0.75rem;

  @media screen and (min-width: 768px) {
    gap: 1rem;
  }
}

li {
  flex-basis: 0;
  list-style-type: none;

  &.is-complete,
  &.is-current {
    .label {
      &:before {
        background: var(--v-vflPrimary-base);
      }
    }
  }

  &.is-mobile {
    .label {
      font-size: 0;
    }
  }

  &.is-small {
    .label:before {
      height: 4px;
    }
  }

  &.is-large {
    .label:before {
      height: 8px;
    }
  }

  .label {
    gap: 0.25rem;
    flex: 1;
    font-size: 0.625rem;

    &:before {
      background: var(--v-vflGreyVeryLight-base);
      border-radius: 9999px;
      content: "";
    }
  }
}
</style>
