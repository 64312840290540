import RestApiClient from "@/infrastructure/restApiClient.js";

export async function getMsTeamsSettings() {
    const path = "/api/ms-teams";
    return await RestApiClient.get(path);
}

export async function saveMsTeamsSettings(settings) {
    const path = "/api/ms-teams";
    return await RestApiClient.post(path, settings);
}

export async function sendAssessments(assessments) {
    const path = "/api/ms-teams/assessments/invites";
    return await RestApiClient.post(path, assessments);
}

export async function linkMsTeamsUser(userToken, tenantId) {
  const path = "/api/ms-teams/link";
  let body = {userToken, tenantId};
  return await RestApiClient.post(path, body);
}