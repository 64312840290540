<template>
  <div class="average-score-card vfl-card-light px-4 py-3 d-flex flex-column">
    <div class="header-container">
      <img :src="icon" aria-hidden="true" alt="" width="20" />
      <span
        class="text-overline vflNeutralDark2--text ml-2"
        style="line-height: 1.2"
        >{{ title }}</span
      >
    </div>
    <template v-if="hasData">
      <div class="d-flex align-center py-6">
        <p class="d-flex align-start mb-0 text-body-2">
          <span class="score mr-1">{{ score }}</span>
          <span class="percentage">%</span>
        </p>
        <average-score-card-label class="ml-3" v-bind="ratingObj" />
      </div>
      <div class="footer text-body-2 vflNeutralDark2--text">
        <i18n class="mb-2" :path="industryTextPath">
          <template v-slot:points>
            <span class="text-body-bold">{{ absoluteIndustryDifference }}</span>
          </template>
        </i18n>
        <p class="mb-0">
          {{
            $tc("averageScoresOverview.card.usersAssessed", peopleAssessed, {
              count: peopleAssessed
            })
          }}
        </p>
      </div>
    </template>

    <average-score-card-label
      v-else
      class="mt-auto mb-auto align-self-start"
      v-bind="noData"
    />
  </div>
</template>

<script>
import AverageScoreCardLabel from "./AverageScoreCardLabel.vue";
import { NO_DATA_COLOURS } from "@/components/dashboard/cards/average-scores/colours.js";

export default {
  name: "AverageScoreCard",
  components: {
    AverageScoreCardLabel
  },
  props: {
    icon: String,
    title: String,
    score: Number,
    industryAverage: Number,
    peopleAssessed: Number,
    ratingObj: Object,
    hasData: Boolean
  },
  computed: {
    industryDifference() {
      return Number((this.score - this.industryAverage).toFixed(1));
    },
    industryTextPath() {
      if (this.industryDifference === 0) {
        return "averageScoresOverview.card.industry.same";
      }

      const comparison = this.industryDifference > 0 ? "above" : "below";
      return `averageScoresOverview.card.industry.${comparison}`;
    },
    absoluteIndustryDifference() {
      return Math.abs(this.industryDifference);
    },
    noData() {
      return {
        backgroundColor: NO_DATA_COLOURS.BACKGROUND,
        text: this.$t("averageScoresOverview.card.noData"),
        textColor: NO_DATA_COLOURS.TEXT
      };
    }
  }
};
</script>

<style scoped lang="scss">
.average-score-card {
  min-height: 150px;
  min-width: 250px;
}

.header-container {
  display: flex;
  align-items: center;
}

.score {
  font-family: Poppins;
  color: var(--v-vflTextDark-base);
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.25rem;
}

.percentage {
  align-self: start;
  font-family: Poppins;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1;
  position: relative;
  top: 4px;
}
</style>
